import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/Layout";
import BGOruzja from "../images/bg_oruzja.jpg"
import styled from "styled-components";
import WeaponCard from "../components/WeaponCard";
import { useStaticQuery, graphql } from "gatsby"

const WeaponsQuery = graphql`
query MyQuery {
  contentfulOruzja {
    oruzjaSlike {
      url,
      title
    }
  }
}
`


const WeaponsPage = () => {
  const data = useStaticQuery(WeaponsQuery);

  return (
    <Layout activeTab="Weapons" bgImage={BGOruzja}>
      <PageHeader>Oružja</PageHeader>
      <WeaponCardsContainer>
        {
          data.contentfulOruzja.oruzjaSlike.map((item, index) => {
            return (
              <WeaponCard key={index} imgTitle={item.title} imgUrl={item.url}>
              </WeaponCard>
            )
          })
        }

      </WeaponCardsContainer>

    </Layout>
  )
}

export default WeaponsPage

const WeaponCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3.125rem;
`

const PageHeader = styled.h1`
  text-align: center;
  color:white;
  margin: 50px;
  text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;

`
