import React from 'react'
import styled from 'styled-components'
import dummyWeapon from '../images/weapon_test.jpg'

const WeaponCard = ({imgTitle, imgUrl}) => {
  return (
    <WeaponCardContainer>
        <WeaponImageContainer>
            <img src={imgUrl}></img>
        </WeaponImageContainer>
        <WeaponNameContainer>
            <h5>{imgTitle}</h5>
        </WeaponNameContainer>

    </WeaponCardContainer>
  )
}

export default WeaponCard

const WeaponCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: white;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0 0.1875rem 0.625rem rgb(0 0 0 / 0.5);
`

const WeaponImageContainer = styled.div`
        text-align:center;

    >img{
        //max-height: 300px;
        //max-width: 400px;
        height: 23rem;
        width: 28rem;
        @media only screen and (min-width: 365px) and (max-width: 600px) {
            width: 34rem;
            height: 29rem;
        }

        /* @media only screen and (min-width: 992px) and (max-width: 1200px) {
            width: 15rem;
        } */

        /* Small devices (portrait tablets and large phones, 600px and up) */
        /* @media only screen and (min-width: 600px) {
            width: 26rem;
        } */
`

const WeaponNameContainer = styled.div`
    background: rgba(70, 67, 67, 1);
    width: 100%;
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid black;

    padding-bottom: 10px;
    >h5{
        margin: 0;
        color: white;
    }
`